@import "../../constants.scss";

.about {
  padding: 100px 20px;
  background: $background-solid;
  color: $primary;

  h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 3px;
      background: $accent;
      margin: 10px auto;
    }
  }

  p {
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.8;
    max-width: 800px;
    margin: 0 auto;
    opacity: 0.85;
  }
}

