
$xs: 575px;
$xs1: 576px;
$sm: 767px;
$sm1: 768px;
$md: 991px;
$md1: 992px;
$lg: 1199px;
$lg1: 1200px;
$xl: 1599px;
$xl1: 1600px;

$bp: $md;
$bp1: $md1;

$primary: #333;
$secondary: #FFFFFF;
$title: #333;
$border: #ddd;
$background-solid: #f7f7f7;
$background-solid-dark: #f5f5f5;
$background-gradient: linear-gradient(120deg, #ece9e6, #ffffff);
$nav1: #1a1a1a;
$nav2: #333;
$contact1: #1a1a1a;
$contact2: #333;
$hero1: #1e3c72;
$hero2: #2a5298;
// $accent: #00bcd4;
// $accent-light: #00e5ff;
$accent: #FFA726;
$accent-light: #FFCC00;