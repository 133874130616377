@import "../../constants.scss";

.contact {
  padding: 100px 20px;
  background: linear-gradient(120deg, $contact1, $contact2);
  color: $secondary;

  h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    color: $accent;

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 3px;
      background: $accent;
      margin: 10px auto;
    }
  }

  .container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  .contactList {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
      font-size: 1.2rem;

      .icon {
        margin-right: 10px;
        font-size: 1.5rem;
        color: $accent;
      }

      a {
        color: $secondary;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: $accent-light;
        }
      }
    }
  }
}


