@import "../../constants.scss";

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 150px 20px;
  background: linear-gradient(120deg, $hero1, $hero2);
  color: $secondary;

  h1 {
    font-size: 3rem;
    margin-bottom: 15px;
    animation: fadeIn 1s ease-in-out;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    opacity: 0.9;
  }

  .btn {
    padding: 12px 30px;
    background: $accent;
    color: $secondary;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    border-radius: 50px;
    transition: background 0.3s, transform 0.2s;

    &:hover {
      background: $accent-light;
      transform: scale(1.1);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

