@import "../../constants.scss";

$margin-lr: 20px;

.navbar {
  background: linear-gradient(90deg, $nav1, $nav2);
  color: #fff;
  padding: 1rem 0px;
  box-shadow: 0 2px 5px #00000033;
  // position: fixed;
  width: 100%;
  z-index: 10;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 20px;
  }

  .logo {
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: $accent;
    transition: color 0.3s;
    margin: 0px 0px 0px $margin-lr;
    &:hover {
      color: $accent-light;
    }
    .personalLogo {
      $logo-hw: 50px;
      width: $logo-hw;
      height: $logo-hw;
      border-radius: 50%;
    }
  }

  .navLinks {
    list-style: none;
    display: flex;
    margin: 0px $margin-lr 0px 0px;
    li {
      margin-left: 30px;

      a {
        color: $secondary;
        text-decoration: none;
        font-size: 1rem;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 0;
          height: 2px;
          background: $accent;
          transition: width 0.3s;
        }

        &:hover::after {
          width: 100%;
        }
      }
    }
  }
}

