@import "../../constants.scss";

.skills {
  padding: 100px 20px;
  background: $background-solid;
  color: $primary;

  h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 3px;
      background: $accent;
      margin: 10px auto;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .skillList {
      $sl-row-gap-mobile: 10px;
      $si-width-mobile: 90%;
      $sl-width-mobile: 100%;
      $sl-column-gap: 50px;
      $si-width: 400px;
      $sl-width: ($si-width * 2) + $sl-column-gap;
  
      display: flex;
      @media screen and (max-width: $bp) {
        width: $sl-width-mobile;
        row-gap: $sl-row-gap-mobile;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0px;
      }
      @media screen and (min-width: $bp1) {
        width: $sl-width;
        column-gap: $sl-column-gap;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 25px;
      }
  
      .skillItem {
        box-sizing: border-box;
        padding: 10px 20px;
        @media screen and (max-width: $bp) {
          width: $si-width-mobile;
          margin: 10px 20px;
        }
        @media screen and (min-width: $bp1) {
          width: $si-width;
          margin: 0px;
        }
        background: $background-solid-dark;
        border: 1px solid $border;
        border-radius: 5px;
        // box-shadow: 0 3px 6px #0000001a;
        font-size: 1rem;
      }
    }
  }
}

