@import "../../constants.scss";

.projects {
  padding: 100px 20px;
  background: $background-gradient;
  color: $primary;

  h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 3px;
      background: $accent;
      margin: 10px auto;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .projectList {
      $pl-row-gap-mobile: 25px;
      $pi-width-mobile: 90%;
      $pl-width-mobile: 100%;

      $pl-column-gap-med: 10px;
      $pi-width-med: 300px;
      $pl-width-med: ($pi-width-med * 2) + $pl-column-gap-med;

      $pl-column-gap: 100px;
      $pi-width: 600px;
      $pl-width: ($pi-width * 2) + $pl-column-gap;

      display: flex;
      @media screen and (max-width: $bp) {
        width: $pl-width-mobile;
        row-gap: $pl-row-gap-mobile;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      @media screen and (min-width: $bp1) {
        flex-wrap: wrap;
        width: $pl-width-med;
        column-gap: $pl-column-gap-med;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 50px;
      }
      @media screen and (min-width: 1328px) {
        column-gap: $pl-column-gap;
        width: $pl-width;
      }
  
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      // gap: 30px;
  
      .projectItem {
        background: $secondary;
        border: 1px solid $border;
        border-radius: 10px;
        padding: 15px 20px;
        // width: 428.664px;
        height: 237.773px;
        text-decoration: none;
        color: $primary;
        // min-width: 428.664px;
        // max-width: 721px;
        @media screen and (max-width: $bp) {
          width: $pi-width-mobile;
        }
        @media screen and (min-width: $bp1) {
          width: $pi-width;
        }
        box-sizing: border-box;
        box-shadow: 0 3px 6px #0000001a;
        text-align: center;
        transition: transform 0.3s, box-shadow 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 6px 12px #00000033;
        }
  
        h3 {
          font-size: 1.5rem;
          margin-bottom: 10px;
          margin-top: 10px;
  
          // &::after {
          //   content: "";
          //   display: block;
          //   width: 25px;
          //   height: 1.5px;
          //   background: $accent;
          //   margin: 2px auto;
          // }
        }
  
        p {
          font-size: 1rem;
          // margin-bottom: 15px;
        }
  
        .viewCode {
          color: $accent;
          font-weight: bold;
          border-bottom: 2px solid transparent;
          transition: border-bottom 0.3s;
  
          &:hover {
            border-bottom: 2px solid $accent;
          }
        }
      }
    }
  }
}

