@import "../../constants.scss";

.footer {
  text-align: center;
  background: #1a1a1a;
  padding: 20px 0;

  p {
    margin: 0;
    font-size: 0.9rem;
    color: $accent;
  }
}

